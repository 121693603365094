import { createSlice } from '@reduxjs/toolkit';
import { getNotifications } from './notifications.actions';

const notifications = createSlice({
  name: 'notifications',
  initialState: {
    notifications: {
      data: [],
      loading: false,
    },
  },
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getNotifications.fulfilled, (state, action) => {
        state.notifications.data = action.payload.data;
      })
      .addCase(getNotifications.pending, (state, action) => {
        state.notifications.loading = true;
      })
      .addCase(getNotifications.rejected, (state, action) => {
        state.notifications.loading = false;
      });
  },
});

export default notifications.reducer;

export const {} = notifications.actions;
