import { createAsyncThunk } from '@reduxjs/toolkit';
import axios from 'axios';
import { env } from '@env';

export const getSettings = createAsyncThunk(
  'settings/getSettings',
  async () => {
    return (await axios.get(`${env.NEBULA_BASE_URL}/v1/admin/settings`)).data;
  }
);

export const updateSettings = createAsyncThunk(
  'settings/updateSettings',
  async (data) => {
    return (
      await axios.put(`${env.NEBULA_BASE_URL}/v1/admin/settings/update`, data)
    ).data;
  }
);
