import React, { useEffect, useState } from 'react';
import { env } from '@env';

import {
  Autocomplete,
  Avatar,
  Box,
  Button,
  Card,
  Container,
  MenuItem,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TablePagination,
  TableRow,
  TextField,
  Typography,
} from '@mui/material';

import { Iconify, Label, Page, Scrollbar, SearchNotFound } from '@components';
import { UserListHead, UserMoreMenu } from '@components/pages/user';
import axios from 'axios';
import { Link as RouterLink } from 'react-router-dom';
import { useSelector } from 'react-redux';

export default function AddComments({ onSave }) {
  const [users, setUsers] = useState();

  const { openAddCommentModal } = useSelector((state) => state.news);

  const [buttonDisabled, setButtonDisabled] = useState(true);

  // users id and comment state like {userId: 1, comment: 'comment'}
  const [comments, setComments] = useState([]);

  const getUsers = () => {
    axios.get(`${env.NEBULA_BASE_URL}/admin/getAllPopilUser`).then((res) => {
      setUsers(res.data);
    });
  };

  useEffect(() => {
    getUsers();
  }, []);

  const handleInputChange = (e, userId) => {
    const { value } = e.target;

    const index = comments.findIndex((item) => item.userId === userId);

    if (index === -1) {
      setComments([...comments, { userId, comment: value }]);
    } else {
      const newComments = [...comments];
      newComments[index].comment = value;
      setComments(newComments);
    }

    setButtonDisabled(false);
  };

  const handleSave = async () => {
    const saveStatus = await onSave(comments);
    setButtonDisabled(true);

    if (saveStatus) {
      setComments([]);
    }
  };

  useEffect(() => {
    if (!openAddCommentModal) {
      setComments([]);
    }
  }, [openAddCommentModal]);

  return (
    <>
      <TableContainer sx={{ minWidth: 800, px: 4 }}>
        <Table>
          <UserListHead
            headLabel={[
              { id: 'email', label: 'Email', alignRight: false },
              { id: 'comment', label: 'Comment' },
            ]}
          />
          <TableBody>
            {users?.data?.map((row) => {
              const { id, username, email } = row;

              return (
                <TableRow hover key={id} tabIndex={-1}>
                  <TableCell align="left">{email}</TableCell>
                  <TableCell align="left">
                    <TextField
                      fullWidth
                      placeholder={`Comment for ${username}`}
                      onChange={(e) => handleInputChange(e, id)}
                      value={
                        comments.find((item) => item.userId === id)?.comment ||
                        ''
                      }
                    />
                  </TableCell>
                </TableRow>
              );
            })}
          </TableBody>

          {users?.length === 0 && (
            <TableBody>
              <TableRow>
                <TableCell align="center" colSpan={6} sx={{ py: 3 }}>
                  <SearchNotFound searchQuery={filterName} />
                </TableCell>
              </TableRow>
            </TableBody>
          )}
        </Table>
      </TableContainer>

      <Box sx={{ mt: 3, display: 'flex', justifyContent: 'flex-end' }}>
        <Button
          variant="contained"
          startIcon={<Iconify icon="eva:plus-fill" />}
          onClick={handleSave}
          disabled={buttonDisabled}
        >
          Save Comments
        </Button>
      </Box>
    </>
  );
}
