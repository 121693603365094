import React, { useEffect, useState } from 'react';
import { Link as RouterLink } from 'react-router-dom';
import axios from 'axios';
import { useDispatch, useSelector } from 'react-redux';
import { format, formatDistanceToNow } from 'date-fns';

import { env } from '@env';
import {
  Avatar,
  Button,
  Card,
  Container,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
  TextField,
  Typography,
} from '@mui/material';
import Page from '@components/shared/Page';
import Label from '@components/shared/Label';
import Scrollbar from '@components/shared/Scrollbar';
import Iconify from '@components/shared/Iconify';
import SearchNotFound from '@components/shared/SearchNotFound';
import { UserListHead, UserMoreMenu } from '@components/pages/user';
import { getUsers, searchUser } from '@features/user/user.actions';
import Pagination from 'react-js-pagination';

export default function User() {
  const dispatch = useDispatch();
  const [activePage, setActivePage] = useState(1);
  const { users } = useSelector((state) => state.user);

  const handleGetUsers = (page = 1) => {
    dispatch(getUsers({ page: page }));

    page ? setActivePage(page) : setActivePage(1);
  };

  const changeUserBlockStatus = (userId) => {
    axios
      .put(`${env.NEBULA_BASE_URL}/v1/admin/users/update/status/${userId}`)
      .then(() => {
        handleGetUsers(activePage);
      });
  };

  const handleSearchUser = (text) => {
    if (!text) {
      handleGetUsers();
    }
    dispatch(searchUser(text)).then((res) => {
      //setUsers({ data: res.payload });
    });
  };

  useEffect(() => {
    handleGetUsers();
  }, []);

  return (
    <Page title="User">
      <Container>
        <Stack
          direction="row"
          alignItems="center"
          justifyContent="space-between"
          mb={5}
        >
          <Typography variant="h4" gutterBottom>
            User
          </Typography>
          <Button
            variant="contained"
            component={RouterLink}
            to="add"
            startIcon={<Iconify icon="eva:plus-fill" />}
          >
            New User
          </Button>
        </Stack>

        <Card>
          <Stack px={5} pt={5} width={300}>
            <TextField
              label="Search User"
              onChange={(event) => handleSearchUser(event.target.value)}
            />
          </Stack>

          <Scrollbar>
            <TableContainer sx={{ minWidth: 800, px: 4 }}>
              <Table>
                <UserListHead
                  headLabel={[
                    { id: 'username', label: 'Username', alignRight: false },
                    { id: 'email', label: 'Email', alignRight: false },
                    {
                      id: 'created_at',
                      label: 'Created At',
                      alignRight: false,
                    },
                    { id: 'admin', label: 'Admin', alignRight: false },
                    { id: 'status', label: 'Status', alignRight: false },
                    { id: '' },
                  ]}
                />
                <TableBody>
                  {users?.docs?.map((row) => {
                    const {
                      id,
                      image,
                      is_admin,
                      status,
                      username,
                      email,
                      created_at,
                    } = row;

                    return (
                      <TableRow hover key={id} tabIndex={-1}>
                        <TableCell component="th" scope="row" padding="none">
                          <Stack
                            direction="row"
                            alignItems="center"
                            spacing={2}
                          >
                            <Avatar alt={username} src={image} />
                            <Typography variant="subtitle2" noWrap>
                              {username ?? 'Null'}
                            </Typography>
                          </Stack>
                        </TableCell>

                        <TableCell align="left">{email}</TableCell>
                        <TableCell align="left">
                          <Typography variant="caption" display="block">
                            {format(new Date(created_at), 'dd MMM yyyy')}
                          </Typography>
                          <Typography
                            variant="caption"
                            display="block"
                            fontSize={10}
                          >
                            {formatDistanceToNow(new Date(created_at), {
                              addSuffix: true,
                            })}
                          </Typography>
                        </TableCell>

                        <TableCell align="left">
                          <Label
                            variant="ghost"
                            color={is_admin ? 'success' : 'error'}
                          >
                            {is_admin ? 'yes' : 'no'}
                          </Label>
                        </TableCell>

                        <TableCell align="left">
                          <Label
                            variant="ghost"
                            color={status ? 'success' : 'error'}
                          >
                            {status ? 'active' : 'banned'}
                          </Label>
                        </TableCell>

                        <TableCell align="right">
                          <UserMoreMenu
                            clickBlockStatus={() =>
                              changeUserBlockStatus(id, status)
                            }
                            userStatus={status}
                          />
                        </TableCell>
                      </TableRow>
                    );
                  })}
                </TableBody>

                {users?.length === 0 && (
                  <TableBody>
                    <TableRow>
                      <TableCell align="center" colSpan={6} sx={{ py: 3 }}>
                        <SearchNotFound searchQuery={filterName} />
                      </TableCell>
                    </TableRow>
                  </TableBody>
                )}
              </Table>
            </TableContainer>
          </Scrollbar>
        </Card>

        {users?.totalPages && (
          <Pagination
            activePage={activePage}
            itemsCountPerPage={users?.limit}
            totalItemsCount={users?.totalDocs}
            pageRangeDisplayed={10}
            onChange={(pageNumber) => handleGetUsers(pageNumber)}
          />
        )}
      </Container>
    </Page>
  );
}
