import React, { useEffect, useState } from 'react';

import {
  Avatar,
  Card,
  Container,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
  Typography,
} from '@mui/material';

import { UserListHead } from '@components/pages/user';
import { CommentMoreMenu } from '@components/pages/more-menu';

import { Label, Page, Scrollbar, SearchNotFound } from '@components';

import { fToNow } from '@utils/formatTime';
import { useDispatch, useSelector } from 'react-redux';
import { getComments } from '@features/comments/comments.actions';
import Pagination from 'react-js-pagination';

export default function NewsComments() {
  const dispatch = useDispatch();
  const { comments } = useSelector((state) => state.comments);
  const [activePage, setActivePage] = useState(1);

  const fetchComments = (page = 0) => {
    dispatch(getComments(page));

    page ? setActivePage(page) : setActivePage(1);
  };

  useEffect(() => {
    fetchComments();
  }, []);

  return (
    <Page title="News Comment">
      <Container>
        <Stack
          direction="row"
          alignItems="center"
          justifyContent="space-between"
          mb={5}
        >
          <Typography variant="h4" gutterBottom>
            News Comments
          </Typography>
        </Stack>

        <Card>
          <Scrollbar>
            <TableContainer sx={{ minWidth: 800, px: 4 }}>
              <Table>
                <UserListHead
                  headLabel={[
                    { id: '' },
                    { id: 'user', label: 'User', alignRight: false },
                    { id: 'comment', label: 'Comment', alignRight: false },
                    { id: 'news', label: 'News', alignRight: false },
                    { id: 'date', label: 'Date', alignRight: false },
                    { id: 'status', label: 'Status', alignRight: false },
                    { id: '' },
                  ]}
                />
                <TableBody>
                  {comments?.data?.docs?.map((item) => (
                    <TableRow hover key={item.id} tabIndex={-1}>
                      <TableCell component="th" scope="row" padding="none">
                        <Stack direction="row" alignItems="center" spacing={2}>
                          <Avatar src={item?.user?.image} />
                        </Stack>
                      </TableCell>
                      <TableCell align="left">{item?.user?.username}</TableCell>
                      <TableCell align="left">{item?.description}</TableCell>
                      <TableCell align="left">{item?.news?.title}</TableCell>
                      <TableCell align="left">
                        {fToNow(item?.created_at)}
                      </TableCell>
                      <TableCell align="left">
                        <Label
                          variant="ghost"
                          color={item.status ? 'success' : 'error'}
                        >
                          {item.status ? 'active' : 'passive'}
                        </Label>
                      </TableCell>

                      <TableCell align="right">
                        <CommentMoreMenu
                          commentStatus={item.status}
                          page={activePage}
                          commentId={item.id}
                        />
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>

                {comments?.data?.docs?.length === 0 && (
                  <TableBody>
                    <TableRow>
                      <TableCell align="center" colSpan={6} sx={{ py: 3 }}>
                        <SearchNotFound searchQuery={filterName} />
                      </TableCell>
                    </TableRow>
                  </TableBody>
                )}
              </Table>
            </TableContainer>
          </Scrollbar>
        </Card>
        {comments?.data?.totalPages && (
          <Pagination
            activePage={activePage}
            itemsCountPerPage={comments?.data?.limit}
            totalItemsCount={comments?.data?.totalDocs}
            pageRangeDisplayed={10}
            onChange={(pageNumber) => fetchComments(pageNumber)}
          />
        )}
      </Container>
    </Page>
  );
}
