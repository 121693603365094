import React, { useEffect, useState } from 'react';
import { Button, Stack, TextField } from '@mui/material';
import { LoadingButton } from '@mui/lab';
import ReactQuill from 'react-quill';
import { useDispatch, useSelector } from 'react-redux';
import { editNews, getNewsDetail } from '@features/news/news.actions';
import { toast } from 'react-toastify';
import { useFormik } from 'formik';
import { styled } from 'styled-components';
import { useParams } from 'react-router-dom';
import axios from 'axios';
import { env } from '@env';

function EditNews() {
  const dispatch = useDispatch();
  const { id } = useParams();
  const { editNewsLoading, newsDetail } = useSelector((state) => state.news);

  const [aiTitleDisabled, setAITitleDisabled] = useState(true);
  const [getContentLoading, setGetContentLoading] = useState(false);

  const formik = useFormik({
    initialValues: {
      title: newsDetail?.title || '',
      aiTitle: newsDetail?.clickbaitTitle || '',
      image: newsDetail?.image || '',
      url: newsDetail?.url || '',
      spot: newsDetail?.spot || '',
      showSpot: newsDetail?.showSpot || false,
    },
    enableReinitialize: true,
    onSubmit: async (values) => {
      const res = await dispatch(
        editNews({
          id: newsDetail?._id,
          title: values.title,
          aiTitle: aiTitleDisabled ? null : values.aiTitle,
          spot: values.spot,
          image: values.image,
          url: values.url,
          showSpot: values.showSpot,
        })
      );

      if (res) {
        toast.success('News has been edited.');
      }
    },
  });

  const getContent = () => {
    setGetContentLoading(true);
    axios
      .get(`${env.NEBULA_BASE_URL}/v1/news/content/${id}`)
      .then((res) => {
        formik.setFieldValue('spot', res?.data?.data?.content);
      })
      .finally(() => {
        setGetContentLoading(false);
      });
  };

  useEffect(() => {
    dispatch(getNewsDetail(id));
  }, [dispatch, id]);

  return (
    <Wrapper>
      <EditNewsHeader>
        <LoadingButton
          fullWidth
          size="small"
          loading={getContentLoading}
          onClick={() => getContent()}
          variant="contained"
        >
          Get Content
        </LoadingButton>
        <Button
          fullWidth
          size="small"
          variant="outlined"
          onClick={() => setAITitleDisabled(!aiTitleDisabled)}
        >
          {aiTitleDisabled ? 'Edit AI Title' : 'Cancel AI Title'}
        </Button>
        <Button
          fullWidth
          size="small"
          variant="outlined"
          onClick={() => {
            setAITitleDisabled(false);
            formik.setFieldValue('aiTitle', formik.values.title);
          }}
        >
          Copy Title
        </Button>
      </EditNewsHeader>
      <img
        src={newsDetail?.image}
        height={200}
        width="100%"
        alt=""
        style={{
          objectFit: 'contain',
          justifyContent: 'flex-start',
          display: 'flex',
        }}
      />
      <form onSubmit={formik.handleSubmit}>
        <Stack spacing={3}>
          <TextField
            name="title"
            label="Title"
            focused
            value={formik.values.title}
            onChange={formik.handleChange}
            multiline
          />
          <TextField
            name="aiTitle"
            label="AI Title"
            value={formik.values.aiTitle}
            onChange={formik.handleChange}
            disabled={aiTitleDisabled}
            multiline
          />
          <ReactQuill
            name="spot"
            theme="snow"
            value={formik.values.spot}
            onChange={(e) => formik.setFieldValue('spot', e)}
          />
          <TextField
            name="image"
            label="Image"
            focused
            value={formik.values.image}
            onChange={formik.handleChange}
            multiline
          />
          <TextField
            name="url"
            label="Original Url"
            focused
            value={formik.values.url}
            onChange={formik.handleChange}
            multiline
          />
          <LoadingButton
            fullWidth
            size="large"
            type="submit"
            variant="contained"
            loading={editNewsLoading}
          >
            Edit
          </LoadingButton>
        </Stack>
      </form>
    </Wrapper>
  );
}

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  gap: 16px;

  @media (max-width: 760px) {
    padding-inline: 16px;
  }
`;

const EditNewsHeader = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
  font-size: 12px;
  color: red;
  overflow: auto;
`;

export default EditNews;
