import { createAsyncThunk } from '@reduxjs/toolkit';
import axios from 'axios';
import { env } from '@env';

export const getSources = createAsyncThunk('source/getSources', async () => {
  return (await axios.get(`${env.NEBULA_BASE_URL}/v1/admin/sources`)).data;
});

export const getCategorySources = createAsyncThunk(
  'source/getCategorySources',
  async (categoryId) => {
    return (
      await axios.get(`${env.NEBULA_BASE_URL}/v1/admin/sources/${categoryId}`)
    ).data;
  }
);

export const updateSourceStatus = createAsyncThunk(
  'source/updateSourceStatus',
  async (id) => {
    return (
      await axios.put(
        `${env.NEBULA_BASE_URL}/v1/admin/sources/update/status/${id}`
      )
    ).data;
  }
);

export const addSource = createAsyncThunk('source/addSource', async (data) => {
  return (await axios.post(`${env.NEBULA_BASE_URL}/v1/admin/sources/add`, data))
    .data;
});

export const editSource = createAsyncThunk(
  'source/editSource',
  async (data) => {
    return (
      await axios.put(
        `${env.NEBULA_BASE_URL}/v1/admin/sources/update/${data?.id}`,
        data
      )
    ).data;
  }
);
