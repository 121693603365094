import { createSlice } from '@reduxjs/toolkit';
import {
  getSettings,
  updateSettings,
} from '@features/settings/settings.actions';

const settings = createSlice({
  name: 'settings',
  initialState: {
    settings: null,
  },
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(getSettings.fulfilled, (state, action) => {
      state.settings = action.payload.data;
    });

    builder.addCase(updateSettings.fulfilled, (state, action) => {
      state.settings = action.payload.data;
    });
  },
});

export default settings.reducer;
