import Router from './routes';
import ThemeProvider from './theme';
import ScrollToTop from '@components/shared/ScrollToTop';
import axios from 'axios';
import { useSelector } from 'react-redux';

// ----------------------------------------------------------------------

export default function App() {
  const user = useSelector((state) => state.user.user);

  axios.defaults.headers.common['Authorization'] = 'Bearer ' + user?.token;

  return (
    <ThemeProvider>
      <ScrollToTop />
      <Router />
    </ThemeProvider>
  );
}
