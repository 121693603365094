import { createAsyncThunk } from '@reduxjs/toolkit';
import axios from 'axios';
import { env } from '@env';

export const getNotifications = createAsyncThunk(
  'notifications/getNotifications',
  async (page) => {
    return (
      await axios.get(
        `${env.NEBULA_BASE_URL}/v1/notifications?page=${page}&limit=20`
      )
    ).data;
  }
);

export const pushNewsNotifications = createAsyncThunk(
  'notifications/pushNewsNotifications',
  async ({ newsId, title }) => {
    return (
      await axios.post(
        `${env.NEBULA_BASE_URL}/v1/admin/notifications/push/news/${newsId}`,
        { title }
      )
    ).data;
  }
);
