import * as Yup from 'yup';
import { useNavigate } from 'react-router-dom';
import { Stack, TextField } from '@mui/material';
import { LoadingButton } from '@mui/lab';
import { login } from '@features/user/user.actions';
import { useDispatch } from 'react-redux';
import { useFormik } from 'formik';
import { toast } from 'react-toastify';

export default function LoginForm() {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const LoginSchema = Yup.object().shape({
    username: Yup.string().required('Username is required'),
    password: Yup.string().required('Password is required'),
  });

  const formik = useFormik({
    initialValues: {
      username: '',
      password: '',
    },
    validationSchema: LoginSchema,
    onSubmit: async (values) => {
      const res = await dispatch(
        login({ username: values.username, password: values.password })
      );

      if (res?.error) {
        toast.error('Kullanıcı adı veya şifre yanlış');
        return null;
      }

      navigate('/dashboard/app', { replace: true });
    },
  });

  return (
    <form onSubmit={formik.handleSubmit}>
      <Stack spacing={3}>
        <TextField
          label="Username"
          name="username"
          onChange={formik.handleChange}
        />

        <TextField
          name="password"
          label="Password"
          type="password"
          onChange={formik.handleChange}
        />
      </Stack>

      <Stack
        direction="row"
        alignItems="center"
        justifyContent="space-between"
        sx={{ my: 2 }}
      >
        <LoadingButton
          fullWidth
          size="large"
          type="submit"
          variant="contained"
          loading={false}
        >
          Login
        </LoadingButton>
      </Stack>
    </form>
  );
}
