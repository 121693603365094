import { createSlice } from '@reduxjs/toolkit';
import {
  getDailyNews,
  getNews,
  deleteNews,
  editNews,
  getSearchNews,
  getNewsReads,
  getPopularNews,
  getNewsDetail,
} from './news.actions';

const news = createSlice({
  name: 'news',
  initialState: {
    news: {},
    readsNews: {
      data: [],
      loading: false,
    },
    popularNews: {
      data: [],
      loading: false,
    },
    newsLoading: false,
    newsDetail: null,
    dailyNews: null,
    editNewsLoading: false,
    openEditNewsModal: false,
    openNewsNotificationModal: false,
    openAddCommentModal: false,
    searchNews: [],
  },
  reducers: {
    setNews: (state, action) => {
      state.news.docs = action.payload;
    },
    setNewsDetail: (state, action) => {
      state.newsDetail = action.payload;
    },
    setOpenEditNewsModal: (state, action) => {
      state.openEditNewsModal = action.payload;
    },
    setOpenNewsNotificationModal: (state, action) => {
      state.openNewsNotificationModal = action.payload;
    },
    setOpenAddCommentModal: (state, action) => {
      state.openAddCommentModal = action.payload;
    },
  },
  extraReducers: (builder) => {
    //getNews
    builder
      .addCase(getNews.pending, (state) => {
        state.newsLoading = true;
        state.news = {};
      })
      .addCase(getNews.fulfilled, (state, action) => {
        state.newsLoading = false;
        state.news = action.payload.data;
      })
      .addCase(getNews.rejected, (state) => {
        state.newsLoading = false;
        state.news = {};
      });

    //deleteNews
    builder.addCase(deleteNews.fulfilled, (state, action) => {
      const newsId = action.meta.arg;
      state.news.docs = state?.news?.docs?.filter(
        (item) => item?._id !== newsId
      );
    });

    //editNews
    builder
      .addCase(editNews.pending, (state) => {
        state.editNewsLoading = true;
      })
      .addCase(editNews.fulfilled, (state, action) => {
        state.editNewsLoading = false;
        state.newsDetail = action.payload.data;
      })
      .addCase(editNews.rejected, (state) => {
        state.editNewsLoading = false;
      });

    //getDailyNews
    builder.addCase(getDailyNews.fulfilled, (state, action) => {
      state.dailyNews = action.payload.data;
    });

    //getSearchNews
    builder.addCase(getSearchNews.fulfilled, (state, action) => {
      state.searchNews = action.payload.data.docs;
    });

    //getNewsReads
    builder
      .addCase(getNewsReads.pending, (state) => {
        state.readsNews.loading = true;
      })
      .addCase(getNewsReads.fulfilled, (state, action) => {
        state.readsNews.data = action.payload.data;
        state.readsNews.loading = false;
      })
      .addCase(getNewsReads.rejected, (state) => {
        state.readsNews.loading = false;
      });

    //getPopularNews
    builder
      .addCase(getPopularNews.pending, (state) => {
        state.popularNews.loading = true;
      })
      .addCase(getPopularNews.fulfilled, (state, action) => {
        state.popularNews.data = action.payload.data;
        state.popularNews.loading = false;
      })
      .addCase(getPopularNews.rejected, (state) => {
        state.popularNews.loading = false;
      });

    //getNewsDetail
    builder.addCase(getNewsDetail.fulfilled, (state, action) => {
      state.newsDetail = action.payload.data;
    });
  },
});

export default news.reducer;

export const {
  setNews,
  setNewsDetail,
  setOpenNewsNotificationModal,
  setOpenEditNewsModal,
  setOpenAddCommentModal,
} = news.actions;
