import { createAsyncThunk } from '@reduxjs/toolkit';
import axios from 'axios';
import { env } from '@env';

export const getTrends = createAsyncThunk('trends/getTrends', async () => {
  return (await axios.get(`${env.NEBULA_BASE_URL}/v1/admin/trends`)).data;
});

export const getTrendDetail = createAsyncThunk(
  'trends/getTrendDetail',
  async (trendId) => {
    return (
      await axios.get(
        `${env.NEBULA_BASE_URL}/v1/admin/trends/detail/${trendId}`
      )
    ).data;
  }
);

export const addTrend = createAsyncThunk('trends/addTrend', async (data) => {
  return (await axios.post(`${env.NEBULA_BASE_URL}/v1/admin/trends/add`, data))
    .data;
});

export const updateTrend = createAsyncThunk(
  'trends/updateTrend',
  async ({ id, data }) => {
    return (
      await axios.put(
        `${env.NEBULA_BASE_URL}/v1/admin/trends/update/${id}`,
        data
      )
    ).data;
  }
);

export const updateTrendStatus = createAsyncThunk(
  'trends/updateTrendStatus',
  async (trendId) => {
    return (
      await axios.put(
        `${env.NEBULA_BASE_URL}/v1/admin/trends/update/status/${trendId}`
      )
    ).data;
  }
);

export const deleteTrend = createAsyncThunk(
  'trends/deleteTopic',
  async (trendId) => {
    return (
      await axios.delete(
        `${env.NEBULA_BASE_URL}/v1/admin/trends/delete/${trendId}`
      )
    ).data;
  }
);
