import App from './App';
import 'simplebar/src/simplebar.css';
import './style/global.css';
import 'react-toastify/dist/ReactToastify.min.css';

import ReactDOM from 'react-dom/client';
import { BrowserRouter } from 'react-router-dom';

import { persistor, store } from './store';
import { Provider } from 'react-redux';
import { PersistGate } from 'redux-persist/integration/react';
import { ToastContainer } from 'react-toastify';

// ----------------------------------------------------------------------

const root = ReactDOM.createRoot(document.getElementById('root'));

root.render(
  <BrowserRouter>
    <Provider store={store}>
      <PersistGate persistor={persistor} loading={null}>
        <App />
        <ToastContainer
          draggableDirection="y"
          draggablePercent={60}
          position="bottom-center"
        />
      </PersistGate>
    </Provider>
  </BrowserRouter>
);
