import React, { useState } from 'react';
import { FormControlLabel, Input, Radio, RadioGroup } from '@mui/material';
import axios from 'axios';
import { toast } from 'react-toastify';
import { env } from '@env';
import { CustomModal, Label } from '@components';
import { useDispatch, useSelector } from 'react-redux';
import {
  setNotificationTitle,
  setNotificationTitleType,
} from '@features/general/general.slice';
import { LoadingButton } from '@mui/lab';
import { setOpenNewsNotificationModal } from '@features/news/news.slice';
import { pushNewsNotifications } from '@features/notifications/notifications.actions';

function SendNotificationModal() {
  const dispatch = useDispatch();
  const { notificationTitle, notificationTitleType } = useSelector(
    (state) => state.general
  );
  const { newsDetail, openNewsNotificationModal } = useSelector(
    (state) => state.news
  );

  const [requestLoading, setRequestLoading] = useState(false);

  const sendNewsNotification = (newsId, title) => {
    setRequestLoading(true);
    dispatch(pushNewsNotifications({ newsId, title }))
      .then(() => {
        toast.success('Your notification has been sent.');
      })
      .catch(() => {
        toast.error('error');
      })
      .finally(() => {
        dispatch(setOpenNewsNotificationModal(false));
        setRequestLoading(false);
      });
  };

  return (
    <CustomModal
      title="Send Notification"
      isOpen={openNewsNotificationModal}
      onClose={() => dispatch(setOpenNewsNotificationModal(false))}
    >
      <div
        style={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'flex-start',
        }}
      >
        <div style={{ marginBottom: 10, overflow: 'hidden' }}>
          <Label variant="filled">Description:</Label> <br />
          <span>{newsDetail?.title}</span>
        </div>
        <Label variant="filled">Title:</Label>
        <RadioGroup
          onChange={(event, value) => {
            dispatch(setNotificationTitleType(value));
            if (value !== 'custom') {
              dispatch(setNotificationTitle(value));
            }
          }}
          name="title"
          defaultValue={newsDetail?.category?.name}
          value={notificationTitleType}
        >
          <FormControlLabel
            key={1}
            value={newsDetail?.category?.name}
            control={<Radio />}
            label={newsDetail?.category?.name}
          />
          <FormControlLabel
            key={1}
            value="Son Dakika"
            control={<Radio />}
            label="Son Dakika"
          />
          <FormControlLabel
            key={1}
            value="Öne Çıkan"
            control={<Radio />}
            label="Öne Çıkan"
          />
          <FormControlLabel
            key={1}
            value="custom"
            control={<Radio />}
            label="Custom"
          />
        </RadioGroup>

        {notificationTitleType === 'custom' ? (
          <Input
            onChange={(event) =>
              dispatch(setNotificationTitle(event?.target?.value))
            }
            placeholder="Notification Title"
          />
        ) : null}

        <LoadingButton
          style={{ marginTop: 16 }}
          variant="contained"
          loading={requestLoading}
          onClick={() =>
            sendNewsNotification(newsDetail?._id, notificationTitle)
          }
        >
          Send
        </LoadingButton>
      </div>
    </CustomModal>
  );
}

export default SendNotificationModal;
