import { styled } from '@mui/material/styles';
import { Autocomplete, InputAdornment, Popper, TextField } from '@mui/material';
import Iconify from '@components/shared/Iconify';

const PopperStyle = styled((props) => (
  <Popper placement="bottom-start" {...props} />
))({
  width: '280px !important',
});

export default function Search({
  posts,
  onChange,
  onChangeInput,
  disabled = false,
  label = 'Search post...',
}) {
  return (
    <Autocomplete
      sx={{ width: 280 }}
      autoHighlight
      popupIcon={null}
      PopperComponent={PopperStyle}
      options={posts}
      getOptionLabel={(post) => post?.title}
      isOptionEqualToValue={(option, value) => option?._id === value?._id}
      onChange={(e) => onChange(e?.target?.innerText)}
      disabled={disabled}
      renderInput={(params) => (
        <TextField
          {...params}
          onChange={(e) => onChangeInput(e?.target?.value)}
          label={label}
          disabled={disabled}
          InputProps={{
            ...params.InputProps,
            startAdornment: (
              <InputAdornment position="start">
                <Iconify
                  icon={'eva:search-fill'}
                  sx={{ ml: 1, width: 20, height: 20, color: 'text.disabled' }}
                />
              </InputAdornment>
            ),
          }}
        />
      )}
    />
  );
}
