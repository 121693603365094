import { createSlice } from '@reduxjs/toolkit';
import { logoutUser, login, getUsers } from './user.actions';

const user = createSlice({
  name: 'user',
  initialState: {
    users: null,
    user: null,
  },
  extraReducers: (builder) => {
    builder
      .addCase(login.fulfilled, (state, action) => {
        state.user = action?.payload?.data;
      })
      .addCase(getUsers.fulfilled, (state, action) => {
        state.users = action?.payload?.data;
      })
      .addCase(logoutUser.fulfilled, (state) => {
        state.user = null;
      });
  },
});

export default user.reducer;
