import { useEffect, useState } from 'react';
import {
  Avatar,
  Button,
  Card,
  Container,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
  TextField,
  Typography,
} from '@mui/material';
import { Iconify, Label, Page, Scrollbar } from '@components';
import { UserListHead } from '@components/pages/user';
import SourceMoreMenu from '@components/pages/sources/SourceMoreMenu';
import { EditSourceModal } from '@components/pages';
import { useDispatch, useSelector } from 'react-redux';
import { getSources } from '@features/source/source.actions';
import { setOpenAddSourceModal } from '@features/source/source.slice';
import AddSourceModal from '@components/pages/sources/AddSourceModal';

export default function Sources() {
  const dispatch = useDispatch();
  const [data, setData] = useState([]);

  const { sources } = useSelector((state) => state.source);

  const searchSource = (text) => {
    if (!text) {
      dispatch(getSources());
    }
    const searchData = data?.filter((item) =>
      item?.name?.toLowerCase()?.includes(text?.toLowerCase())
    );
    setData(searchData);
  };

  useEffect(() => {
    dispatch(getSources());
  }, []);

  return (
    <Page title="Sources">
      <Container>
        <Stack
          direction="row"
          alignItems="center"
          justifyContent="space-between"
          mb={5}
        >
          <Typography variant="h4" gutterBottom>
            Sources
          </Typography>
          <Button
            variant="contained"
            onClick={() => dispatch(setOpenAddSourceModal(true))}
            startIcon={<Iconify icon="eva:plus-fill" />}
          >
            New Source
          </Button>
        </Stack>

        <Card>
          <Stack px={5} pt={5} width={300}>
            <TextField
              label="Ara"
              onChange={(event) => searchSource(event.target.value)}
            />
          </Stack>
          <Scrollbar>
            <TableContainer sx={{ minWidth: 800, px: 4 }}>
              <Table>
                <UserListHead
                  headLabel={[
                    { id: '' },
                    { id: '' },
                    { id: 'slug', label: 'Slug', alignRight: false },
                    {
                      id: 'status',
                      label: 'View on Category',
                      alignRight: false,
                    },
                    { id: '' },
                  ]}
                />
                <TableBody>
                  {sources?.map((row) => {
                    const { id, name, slug, image, status } = row;
                    return (
                      <TableRow hover key={id} tabIndex={-1}>
                        <TableCell align="left">{id}</TableCell>
                        <TableCell component="th" scope="row" padding="none">
                          <Stack
                            direction="row"
                            alignItems="center"
                            spacing={2}
                          >
                            <Avatar alt={name} src={image} />
                            <Typography variant="subtitle2" noWrap>
                              {name ?? 'Null'}
                            </Typography>
                          </Stack>
                        </TableCell>
                        <TableCell align="left">{slug}</TableCell>
                        <TableCell align="left">
                          <Label
                            variant="ghost"
                            color={status ? 'success' : 'error'}
                          >
                            {status ? 'active' : 'passive'}
                          </Label>
                        </TableCell>

                        <TableCell>
                          <SourceMoreMenu source={row} status={status} />
                        </TableCell>
                      </TableRow>
                    );
                  })}
                </TableBody>
              </Table>
            </TableContainer>
          </Scrollbar>

          <EditSourceModal />
          <AddSourceModal />
        </Card>
      </Container>
    </Page>
  );
}
