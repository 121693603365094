import { useEffect, useState } from 'react';
import {
  Avatar,
  Button,
  Card,
  Container,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
  Typography,
} from '@mui/material';
import { Iconify, Label, Page, Scrollbar } from '@components';
import { UserListHead } from '../../components/pages/user';
import { useDispatch, useSelector } from 'react-redux';
import { getCategorySources } from '@features/source/source.actions';
import { deleteSourceFromCategory } from '@features/categories/categories.actions';
import { setOpenAddSourceCategoryModal } from '@features/categories/categories.slice';
import { useParams } from 'react-router-dom';
import AddSourceCategoryModal from '@components/pages/categories/AddSourceCategoryModal';
import Swal from 'sweetalert2';
import { LoadingButton } from '@mui/lab';

export default function CategorySources() {
  const dispatch = useDispatch();
  const params = useParams();

  const [deleteLoading, setDeleteLoading] = useState(false);

  const { categorySources } = useSelector((state) => state.source);

  const deleteSource = (id) => {
    Swal.fire({
      title: 'Are you sure?',
      text: "You won't be able to revert this!",
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Yes, delete it!',
    }).then((result) => {
      if (result.isConfirmed) {
        setDeleteLoading(true);
        dispatch(deleteSourceFromCategory(id)).then(() => {
          setDeleteLoading(false);
        });
        dispatch(getCategorySources(params?.id));
      }
    });
  };

  useEffect(() => {
    dispatch(getCategorySources(params?.id));
  }, [params]);

  return (
    <Page title="Categories">
      <Container>
        <Stack
          direction="row"
          alignItems="center"
          justifyContent="space-between"
          mb={5}
        >
          <Typography variant="h4" gutterBottom>
            Category's Sources
          </Typography>
          <Button
            variant="contained"
            onClick={() => dispatch(setOpenAddSourceCategoryModal(true))}
            startIcon={<Iconify icon="eva:plus-fill" />}
          >
            Add Source
          </Button>
        </Stack>

        <Card>
          <Scrollbar>
            <TableContainer sx={{ minWidth: 800, px: 4 }}>
              <Table>
                <UserListHead
                  headLabel={[
                    { id: 'name', label: 'Name', alignRight: false },
                    { id: 'slug', label: 'Slug', alignRight: false },
                    { id: 'status', label: 'Status', alignRight: false },
                  ]}
                />
                <TableBody>
                  {categorySources?.map((row) => {
                    const { _id, source } = row;
                    return (
                      <TableRow hover key={source?._id} tabIndex={-1}>
                        <TableCell component="th" scope="row" padding="none">
                          <Stack
                            direction="row"
                            alignItems="center"
                            spacing={2}
                          >
                            <Avatar alt={source?.name} src={source?.image} />
                            <Typography variant="subtitle2" noWrap>
                              {source?.name ?? 'Null'}
                            </Typography>
                          </Stack>
                        </TableCell>
                        <TableCell align="left">{source?.slug}</TableCell>
                        <TableCell align="left">
                          <Label
                            variant="ghost"
                            color={source?.status ? 'success' : 'error'}
                          >
                            {source?.status ? 'active' : 'passive'}
                          </Label>
                        </TableCell>
                        <TableCell align="left">
                          <LoadingButton
                            loading={deleteLoading}
                            onClick={() => deleteSource(_id)}
                            variant="contained"
                          >
                            Delete this category
                          </LoadingButton>
                        </TableCell>
                      </TableRow>
                    );
                  })}
                </TableBody>
              </Table>
            </TableContainer>
          </Scrollbar>

          <AddSourceCategoryModal />
        </Card>
      </Container>
    </Page>
  );
}
