import React, { useEffect, useRef, useState } from 'react';
import { Link as RouterLink } from 'react-router-dom';
import '../../style/pagination.css';

import {
  Avatar,
  Button,
  Card,
  Chip,
  Container,
  FormControlLabel,
  IconButton,
  ListItemIcon,
  ListItemText,
  Menu,
  MenuItem,
  Stack,
  Switch,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
  Typography,
} from '@mui/material';

import { Iconify, Page, Scrollbar } from '@components';

import { useDispatch, useSelector } from 'react-redux';

import Pagination from 'react-js-pagination';
import { getNews, getSearchNews } from '@features/news/news.actions';
import { setNews } from '@features/news/news.slice';
import { Search } from 'src/components/pages/news';
import useDebounce from '../../hooks/useDebounce';
import { getCategories } from '@features/categories/categories.actions';
import { UserListHead } from '@components/pages/user';
import { fToNow } from '@utils/formatTime';
import { NewsMoreMenu } from '@components/pages/more-menu';

export default function News() {
  const { news, newsLoading, searchNews } = useSelector((state) => state.news);
  const { categories } = useSelector((state) => state.categories);
  const [activePage, setActivePage] = useState(1);
  const [activeCategory, setActiveCategory] = useState('gundem');
  const [showHiddenSource, setShowHiddenSource] = useState(false);
  const [searchText, setSearchText] = useState('');
  const debounceSearchText = useDebounce(searchText, 500);
  const dispatch = useDispatch();

  const fetchNews = async (page = 1, category) => {
    dispatch(getNews({ category, page, showHiddenSource }));

    page ? setActivePage(page) : setActivePage(1);
  };

  const fetchSearchNews = async (title) => {
    const news = await dispatch(getSearchNews(title)).then(
      (data) => data?.payload?.data?.docs
    );
    dispatch(setNews(news));

    if (title === undefined) {
      fetchNews(1, activeCategory);
    }
  };

  useEffect(() => {
    if (debounceSearchText) {
      dispatch(getSearchNews(debounceSearchText));
    }
  }, [debounceSearchText]);

  useEffect(() => {
    dispatch(getCategories());
  }, []);

  useEffect(() => {
    fetchNews(1, activeCategory);
  }, [showHiddenSource]);

  return (
    <Page title="News">
      <Container>
        <Stack
          direction="row"
          alignItems="center"
          justifyContent="space-between"
          mb={5}
        >
          <Typography variant="h4">News</Typography>

          <Stack direction="row" gap={2}>
            <Button
              variant="contained"
              component={RouterLink}
              to="add"
              startIcon={<Iconify icon="eva:plus-fill" />}
            >
              Add News
            </Button>
          </Stack>
        </Stack>

        <Stack
          mb={2}
          direction="row"
          alignItems="center"
          justifyContent="space-between"
        >
          <Search
            label="Search News"
            disabled={newsLoading}
            posts={searchNews ?? news?.docs}
            onChangeInput={(e) => setSearchText(e)}
            onChange={(e) => fetchSearchNews(e)}
          />
        </Stack>

        <Stack mb={2} alignItems="flex-start" p={0}>
          <FormControlLabel
            style={{ marginLeft: 0 }}
            control={<Switch color="success" />}
            label="Hidden Sources"
            disabled={newsLoading}
            labelPlacement="end"
            onChange={(event, checked) => setShowHiddenSource(checked)}
            color="red"
          />
        </Stack>

        <Stack
          mb={5}
          direction="row"
          alignItems="center"
          justifyContent="flex-start"
          overflow="auto"
          gap={2}
        >
          {categories?.map((category) => (
            <Chip
              color="info"
              disabled={newsLoading}
              variant={category?.slug !== activeCategory ? 'outlined' : ''}
              onClick={() => {
                fetchNews(0, category?.slug);
                setActiveCategory(category?.slug);
              }}
              label={category?.name}
            />
          ))}
          <Chip
            color="info"
            disabled={newsLoading}
            variant="outlined"
            component={RouterLink}
            onClick={() => {}}
            to="/dashboard/popular-news"
            label="Popular"
          />
          <Chip
            color="info"
            disabled={newsLoading}
            variant="outlined"
            component={RouterLink}
            onClick={() => {}}
            to="/dashboard/daily-news"
            label="Daily"
          />
          <Chip
            color="info"
            disabled={newsLoading}
            variant="outlined"
            component={RouterLink}
            onClick={() => {}}
            to="trends"
            label="Google Trends"
          />
        </Stack>

        <Card>
          <Scrollbar>
            <TableContainer sx={{ minWidth: 800, px: 4 }}>
              <Table>
                <UserListHead
                  headLabel={[
                    { id: '' },
                    { id: 'title', label: 'Title', alignRight: false },
                    { id: 'source', label: 'Source', alignRight: false },
                    { id: 'date', label: 'Date', alignRight: false },
                    { id: '' },
                  ]}
                />
                <TableBody>
                  {news?.docs?.map((item) => (
                    <TableRow hover key={item?._id} tabIndex={-1}>
                      <TableCell component="th" scope="row" padding="none">
                        <Stack direction="row" alignItems="center" spacing={2}>
                          <Avatar src={item?.image} />
                        </Stack>
                      </TableCell>
                      <TableCell sx={{ width: 500 }} align="left">
                        {item?.title}
                      </TableCell>
                      <TableCell align="left">
                        <Stack direction="row" alignItems="center" spacing={2}>
                          <Avatar
                            sx={{ width: 24, height: 24 }}
                            src={item?.source?.image}
                          />
                          <span>{item?.source?.name}</span>
                        </Stack>
                      </TableCell>
                      <TableCell align="left">{fToNow(item?.date)}</TableCell>

                      <TableCell align="right">
                        <NewsMoreMenu news={item} />
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          </Scrollbar>
        </Card>

        {news?.totalPages && (
          <Pagination
            activePage={activePage}
            itemsCountPerPage={news?.limit}
            totalItemsCount={news?.totalDocs}
            pageRangeDisplayed={10}
            onChange={(pageNumber) => fetchNews(pageNumber, activeCategory)}
          />
        )}
      </Container>
    </Page>
  );
}
