import React, { useEffect } from 'react';
import {
  Autocomplete,
  Button,
  Container,
  Divider,
  Stack,
  TextField,
  Typography,
} from '@mui/material';
import 'react-quill/dist/quill.snow.css';
import { useFormik } from 'formik';
import { useDispatch, useSelector } from 'react-redux';
import { getTrendDetail, updateTrend } from '@features/trends/trends.actions';
import * as Yup from 'yup';
import { useParams } from 'react-router-dom';
import { toast } from 'react-toastify';

function EditTrends() {
  const params = useParams();
  const dispatch = useDispatch();

  const { trendDetail } = useSelector((state) => state.trends);

  const validationSchema = Yup.object().shape({
    title: Yup.string().required('Zorunlu'),
    desc: Yup.string().required('Zorunlu'),
    tags: Yup.array().required('Zorunlu'),
  });

  const formik = useFormik({
    initialValues: {
      image: null,
      title: trendDetail?.title,
      desc: trendDetail?.desc,
      tags: trendDetail?.tags?.split(','),
    },
    validationSchema: validationSchema,
    enableReinitialize: true,
    onSubmit: async (values) => {
      const tags = values.tags.join(',');
      const data = { ...values, tags };

      const formData = new FormData();
      Object.keys(data).forEach((key) => {
        formData.append(key, data[key]);
      });

      dispatch(updateTrend({ id: params?.id, data: formData }))
        .then(() => {
          toast.success('Trend updated.');
        })
        .catch(() => {
          toast.error('error.');
        });
    },
  });

  useEffect(() => {
    dispatch(getTrendDetail(params?.id));
  }, [params?.id]);

  return (
    <Container>
      <form onSubmit={formik.handleSubmit}>
        <Stack
          direction="row"
          alignItems="center"
          justifyContent="space-between"
          mb={2}
        >
          <Typography variant="h3" gutterBottom>
            Edit <span className="ant-tag-red">{trendDetail?.title}</span> Trend
          </Typography>
        </Stack>
        <Stack spacing={2}>
          <div>
            <Typography fontWeight="bold" gutterBottom>
              Fotoğraf:
            </Typography>
            <input
              type="file"
              name="image"
              onChange={(event) => {
                formik.setFieldValue('image', event.currentTarget.files[0]);
              }}
            />
            <div style={{ color: 'red', marginTop: 10 }}>
              {formik.touched.image && formik.errors.image}
            </div>
            <img src={trendDetail?.image} height={100} alt="topic" />
          </div>
          <Divider />
          <TextField
            id="outlined-basic"
            variant="outlined"
            error={formik.touched.title && formik.errors.title}
            helperText={formik.touched.title && formik.errors.title}
            label="Title"
            value={formik.values.title}
            name="title"
            onChange={formik.handleChange}
          />
          <TextField
            id="outlined-basic"
            variant="outlined"
            label="Desc"
            name="desc"
            rows={10}
            multiline
            value={formik.values.desc}
            onChange={formik.handleChange}
            error={formik.touched.desc && formik.errors.desc}
            helperText={formik.touched.desc && formik.errors.desc}
          />
          <Autocomplete
            multiple
            id="tags-standard"
            freeSolo
            options={[]}
            onChange={(event, value) => formik.setFieldValue('tags', value)}
            value={formik?.values?.tags}
            renderInput={(params) => (
              <TextField
                {...params}
                name="tags"
                error={formik.touched.tags && formik.errors.tags}
                helperText={formik.touched.tags && formik.errors.tags}
                variant="standard"
                label="Tags"
                placeholder="Tag"
              />
            )}
          />
          <Button type="submit" variant="outlined">
            Update
          </Button>
        </Stack>
      </form>
    </Container>
  );
}

export default EditTrends;
