import { createAsyncThunk } from '@reduxjs/toolkit';
import axios from 'axios';
import { env } from '@env';

export const login = createAsyncThunk('user/login', async (data) => {
  return (await axios.post(`${env.NEBULA_BASE_URL}/v1/auth/login`, data)).data;
});

export const getUsers = createAsyncThunk('user/getUsers', async ({ page }) => {
  return (
    await axios.get(
      `${env.NEBULA_BASE_URL}/v1/admin/users?page=${page}?limit=10`
    )
  ).data;
});

export const logoutUser = createAsyncThunk('user/logoutUser', async (data) => {
  return (await axios.post(`${env.NEBULA_BASE_URL}/v1/auth/logout`, data)).data;
});

export const addUser = createAsyncThunk('user/searchUser', async (data) => {
  return (await axios.post(`${env.NEBULA_BASE_URL}/v1/admin/users/add`, data))
    .data;
});

export const searchUser = createAsyncThunk('user/searchUser', async (text) => {
  return (
    await axios.post(`${env.NEBULA_BASE_URL}/admin/searchUser`, {
      username: text,
    })
  ).data;
});
