import { createAsyncThunk } from '@reduxjs/toolkit';
import axios from 'axios';
import { env } from '@env';

export const getCategories = createAsyncThunk(
  'categories/getCategories',
  async (data) => {
    return (await axios.post(`${env.NEBULA_BASE_URL}/v1/categories`, data))
      .data;
  }
);

export const addSourceToCategory = createAsyncThunk(
  'categories/addSourceToCategory',
  async (data) => {
    return (
      await axios.post(
        `${env.NEBULA_BASE_URL}/v1/admin/categories/addSource`,
        data
      )
    ).data;
  }
);

export const deleteSourceFromCategory = createAsyncThunk(
  'categories/addSourceToCategory',
  async (id) => {
    return (
      await axios.delete(
        `${env.NEBULA_BASE_URL}/v1/admin/categories/deleteSource`,
        {
          id,
        }
      )
    ).data;
  }
);
