import { createAsyncThunk } from '@reduxjs/toolkit';
import axios from 'axios';
import { env } from '@env';

export const getGeneralAnalytics = createAsyncThunk(
  'analytics/getGeneralAnalytics',
  async () => {
    return (
      await axios.get(`${env.NEBULA_BASE_URL}/v1/admin/analytics/general`)
    ).data;
  }
);
