import React, { useEffect, useState } from 'react';
import { Page } from '@components';
import {
  Avatar,
  Button,
  Card,
  Container,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
  Typography,
} from '@mui/material';
import Scrollbar from '@components/shared/Scrollbar';
import { UserListHead } from 'src/components/pages/user';
import { env } from '@env';
import axios from 'axios';
import Swal from 'sweetalert2';
import { useDispatch, useSelector } from 'react-redux';
import Pagination from 'react-js-pagination';
import { getPopularNews } from '@features/news/news.actions';

function PopularNews() {
  const dispatch = useDispatch();
  const { popularNews } = useSelector((state) => state.news);
  const [activePage, setActivePage] = useState(1);

  const fetchPopularNews = async (page = 1) => {
    dispatch(getPopularNews(page));

    page ? setActivePage(page) : setActivePage(1);
  };

  const deletePopularNews = async (id) => {
    Swal.fire({
      title: 'Are you sure?',
      text: "You won't be able to revert this!",
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Yes, delete it!',
    }).then((result) => {
      if (result.isConfirmed) {
        axios
          .delete(`${env.NEBULA_BASE_URL}/v1/admin/news/popular/delete`, {
            id,
          })
          .then(() => {
            dispatch(getPopularNews(activePage));
          });
      }
    });
  };

  useEffect(() => {
    fetchPopularNews();
  }, []);

  return (
    <Page title="Popular News">
      <Container>
        <Stack
          direction="row"
          alignItems="center"
          justifyContent="space-between"
          mb={5}
        >
          <Typography variant="h4" gutterBottom>
            Popular News
          </Typography>
        </Stack>

        <Card>
          <Scrollbar>
            <TableContainer sx={{ minWidth: 800, px: 4 }}>
              <Table>
                <UserListHead
                  headLabel={[
                    { id: 'name', label: 'Name', alignRight: false },
                    { id: '' },
                  ]}
                />
                <TableBody>
                  {popularNews?.data?.docs?.map((row) => {
                    return (
                      <TableRow hover key={row?.news?.id} tabIndex={-1}>
                        <TableCell component="th" scope="row" padding="none">
                          <Stack
                            direction="row"
                            alignItems="center"
                            spacing={2}
                          >
                            <Avatar
                              alt={row?.news?.title}
                              src={row?.news?.image}
                            />
                            <Typography variant="subtitle2">
                              {row?.news?.title ?? 'Null'}
                            </Typography>
                          </Stack>
                        </TableCell>

                        <TableCell align="right">
                          <Button
                            onClick={() => deletePopularNews(row?._id)}
                            color="error"
                          >
                            Sil
                          </Button>
                        </TableCell>
                      </TableRow>
                    );
                  })}
                </TableBody>
              </Table>
            </TableContainer>
          </Scrollbar>
        </Card>

        {popularNews?.data?.totalPages && (
          <Pagination
            activePage={activePage}
            itemsCountPerPage={popularNews?.data?.limit}
            totalItemsCount={popularNews?.data?.totalDocs}
            pageRangeDisplayed={10}
            onChange={(pageNumber) => fetchPopularNews(pageNumber)}
          />
        )}
      </Container>
    </Page>
  );
}

export default PopularNews;
