import { useEffect } from 'react';
import {
  Avatar,
  Button,
  Card,
  Container,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
  Typography,
} from '@mui/material';
import { Label, Page, Scrollbar } from '@components';
import { UserListHead } from '../../components/pages/user';
import { useDispatch, useSelector } from 'react-redux';
import { getCategories } from '@features/categories/categories.actions';
import { Link } from 'react-router-dom';

export default function Categories() {
  const dispatch = useDispatch();

  const { categories } = useSelector((state) => state.categories);

  useEffect(() => {
    dispatch(getCategories());
  }, []);

  return (
    <Page title="Categories">
      <Container>
        <Stack
          direction="row"
          alignItems="center"
          justifyContent="space-between"
          mb={5}
        >
          <Typography variant="h4" gutterBottom>
            Categories
          </Typography>
        </Stack>

        <Card>
          <Scrollbar>
            <TableContainer sx={{ minWidth: 800, px: 4 }}>
              <Table>
                <UserListHead
                  headLabel={[
                    { id: 'name', label: 'Name', alignRight: false },
                    { id: 'slug', label: 'Slug', alignRight: false },
                    {
                      id: 'sources',
                      label: 'Sources',
                      alignRight: false,
                    },
                    { id: 'status', label: 'Status', alignRight: false },
                    { id: '', label: '', alignRight: false },
                  ]}
                />
                <TableBody>
                  {categories?.map((row) => {
                    const { _id, name, slug, sources, status } = row;
                    return (
                      <TableRow hover key={_id} tabIndex={-1}>
                        <TableCell component="th" scope="row" padding="none">
                          <Stack
                            direction="row"
                            alignItems="center"
                            spacing={2}
                          >
                            <Typography variant="subtitle2" noWrap>
                              {name ?? 'Null'}
                            </Typography>
                          </Stack>
                        </TableCell>
                        <TableCell align="left">{slug}</TableCell>
                        <TableCell align="left">{sources?.length}</TableCell>
                        <TableCell align="left">
                          <Label
                            variant="ghost"
                            color={status ? 'success' : 'error'}
                          >
                            {status ? 'active' : 'passive'}
                          </Label>
                        </TableCell>
                        <TableCell align="left">
                          <Link to={`/dashboard/categories/${_id}`}>
                            <Button variant="contained">Sources</Button>
                          </Link>
                        </TableCell>
                        {/*
                        <TableCell align="right">
                          <SourceMoreMenu source={row} status={status} />
                        </TableCell>*/}
                      </TableRow>
                    );
                  })}
                </TableBody>
              </Table>
            </TableContainer>
          </Scrollbar>
        </Card>
      </Container>
    </Page>
  );
}
