import { createSlice } from '@reduxjs/toolkit';
import { getGeneralAnalytics } from '@features/analytics/analytics.actions';

const analytics = createSlice({
  name: 'analytics',
  initialState: {
    general: null,
  },
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(getGeneralAnalytics.fulfilled, (state, action) => {
      state.general = action.payload.data;
    });
  },
});

export default analytics.reducer;

export const {} = analytics.actions;
