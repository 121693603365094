import React, { useState, useEffect } from 'react';

import { Chip, Container, Grid, Stack, Typography } from '@mui/material';
import axios from 'axios';
import { env } from '@env';
import { PostCard } from '@components/pages/news';

export default function TrendNews() {
  const [allData, setAllData] = useState([]);
  const [trendNews, setTrendNews] = useState([]);
  const [trendsWords, setTrendsWords] = useState([]);
  const [activeWord, setActiveWord] = useState('');
  const [loading, setLoading] = useState(true);

  const getTrends = async () => {
    const response = await axios.get(
      `${env.NEBULA_BASE_URL}/v1/admin/trends/google`
    );

    if (response.data.status) {
      const { data } = response.data;
      setAllData(data);
      const words = data.map((x) => x.search);
      setTrendsWords(words);
      setActiveWord(words[0]);
    }

    setLoading(false);
  };

  useEffect(() => {
    getTrends();
  }, []);

  useEffect(() => {
    const news = allData.find((x) => x.search === activeWord);
    if (news) {
      setTrendNews(news.news);
    }
  }, [activeWord]);

  if (loading) return <Container>Loading...</Container>;

  return (
    <Container>
      <Stack
        direction="row"
        alignItems="center"
        justifyContent="space-between"
        mb={5}
      >
        <Typography variant="h4" gutterBottom>
          Trend News
        </Typography>
      </Stack>

      <Stack
        mb={5}
        direction="row"
        alignItems="center"
        justifyContent="flex-start"
        overflow="auto"
        gap={2}
        pb={2}
      >
        {trendsWords.map((word) => (
          <Chip
            color="info"
            variant={word !== activeWord ? 'outlined' : ''}
            onClick={() => {
              setActiveWord(word);
            }}
            label={word}
          />
        ))}
      </Stack>

      {trendNews.length > 0 ? (
        <Grid container spacing={3}>
          {trendNews.map((item, index) => (
            <PostCard
              key={item.id}
              news={item}
              title={item.title}
              url={`${env.FRONT_URL}/news/${item?.slug}`}
              avatar={item?.source?.image}
              name={item?.source?.name}
              createdAt={item?.date}
              image={item.image}
              index={index}
              showIconNumber={false}
              showInfo={false}
              showActionButtons={true}
            />
          ))}
        </Grid>
      ) : (
        <Typography variant="h6" gutterBottom>
          No news found.
        </Typography>
      )}
    </Container>
  );
}
