import React, { useEffect, useState } from 'react';
import {
  Card,
  Container,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
  Typography,
} from '@mui/material';

import { Page, Scrollbar, SearchNotFound } from '@components';

import { UserListHead } from 'src/components/pages/user';
import { useDispatch, useSelector } from 'react-redux';
import { fToNow } from '@utils/formatTime';
import { getNotifications } from '@features/notifications/notifications.actions';
import Pagination from 'react-js-pagination';

export default function Notification() {
  const dispatch = useDispatch();
  const { notifications } = useSelector((state) => state.notifications);
  const [activePage, setActivePage] = useState(1);

  const fetchNotification = (page = 1) => {
    dispatch(getNotifications(page));

    page ? setActivePage(page) : setActivePage(1);
  };

  useEffect(() => {
    fetchNotification();
  }, []);

  return (
    <Page title="Notifications">
      <Container>
        <Stack
          direction="row"
          alignItems="center"
          justifyContent="space-between"
          mb={5}
        >
          <Typography variant="h4" gutterBottom>
            Notifications
          </Typography>
          {/*  <Button
            variant="contained"
            onClick={() => setOpenModal(true)}
            component={RouterLink}
            to="#"
            startIcon={<Iconify icon="eva:plus-fill" />}
          >
            Send Notification
          </Button>*/}
        </Stack>

        <Card>
          <Scrollbar>
            <TableContainer sx={{ minWidth: 800, px: 4 }}>
              <Table>
                <UserListHead
                  headLabel={[
                    { id: 'title', label: 'Title', alignRight: false },
                    { id: 'body', label: 'Body', alignRight: false },
                    { id: 'date', label: 'Date', alignRight: false },
                  ]}
                />
                <TableBody>
                  {notifications?.data?.docs?.map((item) => (
                    <TableRow hover key={item?.id} tabIndex={-1}>
                      <TableCell align="left">{item?.title}</TableCell>
                      <TableCell align="left">{item?.body}</TableCell>
                      <TableCell align="left">
                        {fToNow(item?.news?.created_at)}
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>

                {notifications?.length === 0 && (
                  <TableBody>
                    <TableRow>
                      <TableCell align="center" colSpan={6} sx={{ py: 3 }}>
                        <SearchNotFound searchQuery={filterName} />
                      </TableCell>
                    </TableRow>
                  </TableBody>
                )}
              </Table>
            </TableContainer>
          </Scrollbar>
        </Card>

        {notifications?.data?.totalPages && (
          <Pagination
            activePage={activePage}
            itemsCountPerPage={notifications?.data?.limit}
            totalItemsCount={notifications?.data?.totalDocs}
            pageRangeDisplayed={10}
            onChange={(pageNumber) => fetchNotification(pageNumber)}
          />
        )}
      </Container>
    </Page>
  );
}
