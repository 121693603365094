import { forwardRef } from 'react';
import { Box } from '@mui/material';

const Page = forwardRef(({ children, title = '', meta, ...other }, ref) => {
  return (
    <>
      <head>
        <title>{`${title} | Popil`}</title>
      </head>

      <Box ref={ref} {...other}>
        {children}
      </Box>
    </>
  );
});

export default Page;
