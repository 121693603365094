import { createSlice } from '@reduxjs/toolkit';
import { getCategories } from './categories.actions';

const categories = createSlice({
  name: 'categories',
  initialState: {
    categories: null,
    openAddSourceCategoryModal: false,
  },
  reducers: {
    setOpenEditSourceModal(state, action) {
      state.openEditSourceModal = action.payload;
    },
    setOpenAddSourceCategoryModal(state, action) {
      state.openAddSourceCategoryModal = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getCategories.fulfilled, (state, action) => {
      state.categories = action.payload.data;
    });
  },
});

export default categories.reducer;

export const { setOpenAddSourceCategoryModal } = categories.actions;
