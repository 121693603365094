import React, { useEffect, useState } from 'react';

import {
  Avatar,
  Card,
  Container,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
  Typography,
} from '@mui/material';

import { UserListHead } from 'src/components/pages/user';

import { Page, Scrollbar, SearchNotFound } from '@components';

import { fToNow } from '@utils/formatTime';
import { useDispatch, useSelector } from 'react-redux';
import { getNewsReads } from '@features/news/news.actions';
import Pagination from 'react-js-pagination';

export default function NewsReads() {
  const dispatch = useDispatch();
  const { readsNews } = useSelector((state) => state.news);
  const [activePage, setActivePage] = useState(1);

  const fetchNewsReads = (page = 1) => {
    dispatch(getNewsReads(page));

    page ? setActivePage(page) : setActivePage(1);
  };

  useEffect(() => {
    fetchNewsReads();
  }, []);

  return (
    <Page title="News Comment">
      <Container>
        <Stack
          direction="row"
          alignItems="center"
          justifyContent="space-between"
          mb={5}
        >
          <Typography variant="h4" gutterBottom>
            News Reads
          </Typography>
        </Stack>

        <Card>
          <Scrollbar>
            <TableContainer sx={{ minWidth: 800, px: 4 }}>
              <Table>
                <UserListHead
                  headLabel={[
                    { id: 'user', label: 'User', alignRight: false },
                    { id: 'news', label: 'News', alignRight: false },
                    { id: 'date', label: 'Date', alignRight: false },
                  ]}
                />
                <TableBody>
                  {readsNews?.data?.docs?.map((item) => (
                    <TableRow hover key={item.id} tabIndex={-1}>
                      <TableCell component="th" scope="row" padding="none">
                        <Stack direction="row" alignItems="center" spacing={2}>
                          <Avatar src={item?.user?.image} />
                          <Typography variant="subtitle2" noWrap>
                            {item?.user?.username ??
                              item?.user?.name ??
                              `Guest (${item?.device?.brand})`}
                          </Typography>
                        </Stack>
                      </TableCell>
                      <TableCell align="left">
                        {item?.news?.title ?? 'Deleted'}
                      </TableCell>

                      <TableCell align="left">
                        {fToNow(item?.created_at)}
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>

                {readsNews?.data?.docs?.length === 0 && (
                  <TableBody>
                    <TableRow>
                      <TableCell align="center" colSpan={6} sx={{ py: 3 }}>
                        <SearchNotFound searchQuery={filterName} />
                      </TableCell>
                    </TableRow>
                  </TableBody>
                )}
              </Table>
            </TableContainer>
          </Scrollbar>
        </Card>

        {readsNews?.data?.totalPages && (
          <Pagination
            activePage={activePage}
            itemsCountPerPage={readsNews?.data?.limit}
            totalItemsCount={readsNews?.data?.totalDocs}
            pageRangeDisplayed={10}
            onChange={(pageNumber) => fetchNewsReads(pageNumber)}
          />
        )}
      </Container>
    </Page>
  );
}
