import { combineReducers } from '@reduxjs/toolkit';
import news from '@features/news/news.slice';
import general from '@features/general/general.slice';
import settings from '@features/settings/settings.slice';
import categories from '@features/categories/categories.slice';
import user from '@features/user/user.slice';
import source from '@features/source/source.slice';
import analytics from '@features/analytics/analytics.slice';
import comments from '@features/comments/comments.slice';
import notifications from '@features/notifications/notifications.slice';
import trends from '@features/trends/trends.slice';

export default combineReducers({
  user,
  source,
  categories,
  news,
  general,
  settings,
  analytics,
  comments,
  notifications,
  trends,
});
