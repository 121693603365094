import { createSlice } from '@reduxjs/toolkit';
import { getComments } from '@features/comments/comments.actions';

const comments = createSlice({
  name: 'comments',
  initialState: {
    comments: {
      data: [],
      loading: false,
    },
  },
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getComments.pending, (state) => {
        state.comments.loading = true;
      })
      .addCase(getComments.fulfilled, (state, action) => {
        state.comments.data = action.payload.data;
        state.comments.loading = false;
      })
      .addCase(getComments.rejected, (state) => {
        state.comments.loading = false;
      });
  },
});

export default comments.reducer;

export const {} = comments.actions;
