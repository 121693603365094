import { createSlice } from '@reduxjs/toolkit';

const generalReducer = createSlice({
  name: 'general',
  initialState: {
    notificationTitle: null,
    notificationTitleType: null,
  },
  reducers: {
    setNotificationTitle(state, action) {
      state.notificationTitle = action.payload;
    },
    setNotificationTitleType(state, action) {
      state.notificationTitleType = action.payload;
    },
  },
  extraReducers: {},
});

export default generalReducer.reducer;

export const { setNotificationTitle, setNotificationTitleType, setTopics } =
  generalReducer.actions;
