import { createAsyncThunk } from '@reduxjs/toolkit';
import axios from 'axios';
import { env } from '@env';

export const getNews = createAsyncThunk(
  'news/getNews',
  async ({ category, page, showHiddenSource = 0 }) => {
    return (
      await axios.post(`${env.NEBULA_BASE_URL}/v1/news?page=${page}&limit=20`, {
        category_slug: category,
      })
    ).data;
  }
);

export const getNewsDetail = createAsyncThunk(
  'news/getNewsDetail',
  async (id) => {
    return (
      await axios.get(`${env.NEBULA_BASE_URL}/v1/admin/news/detail/${id}`)
    ).data;
  }
);

export const addNews = createAsyncThunk('news/addNews', async (data) => {
  return (await axios.post(`${env.NEBULA_BASE_URL}/v1/admin/news/add`, data))
    .data;
});

export const deleteNews = createAsyncThunk(
  'news/deleteNews',
  async (newsId) => {
    return (
      await axios.delete(
        `${env.NEBULA_BASE_URL}/v1/admin/news/delete/${newsId}`
      )
    ).data;
  }
);

export const editNews = createAsyncThunk('news/editNews', async (data) => {
  return (
    await axios.put(
      `${env.NEBULA_BASE_URL}/v1/admin/news/update/${data?.id}`,
      data
    )
  ).data;
});

export const getDailyNews = createAsyncThunk('news/getDailyNews', async () => {
  return (await axios.get(`${env.NEBULA_BASE_URL}/v1/news/daily`)).data;
});

export const addDailyNews = createAsyncThunk(
  'news/addDailyNews',
  async (newsId) => {
    return (
      await axios.post(`${env.NEBULA_BASE_URL}/v1/admin/news/daily/add`, {
        news_id: newsId,
      })
    ).data;
  }
);

export const deleteDailyNews = createAsyncThunk(
  'news/deleteDailyNews',
  async (id) => {
    return (
      await axios.delete(
        `${env.NEBULA_BASE_URL}/v1/admin/news/daily/delete/${id}`
      )
    ).data;
  }
);

export const addFakeComments = createAsyncThunk(
  'news/addFakeComments',
  async (data) => {
    return (
      await axios.post(
        `${env.NEBULA_BASE_URL}/v1/admin/comments/add/fake-comments`,
        data
      )
    ).data;
  }
);

export const getSearchNews = createAsyncThunk(
  'news/searchNews',
  async (key) => {
    return (
      await axios.get(`${env.NEBULA_BASE_URL}/v1/news/search/${key}?limit=10`)
    ).data;
  }
);

export const getNewsReads = createAsyncThunk(
  'news/getNewsReads',
  async (page) => {
    return (
      await axios.get(
        `${env.NEBULA_BASE_URL}/v1/admin/news/reads?page=${page}&limit=20`
      )
    ).data;
  }
);

export const getPopularNews = createAsyncThunk(
  'news/getPopularNews',
  async (page) => {
    return (
      await axios.get(
        `${env.NEBULA_BASE_URL}/v1/admin/news/popular?page=${page}&limit=20`
      )
    ).data;
  }
);

export const addPopularNews = createAsyncThunk(
  'news/addPopularNews',
  async (news_id) => {
    return (
      await axios.post(`${env.NEBULA_BASE_URL}/v1/admin/news/popular/add`, {
        news_id,
      })
    ).data;
  }
);
