import { useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { Container, Typography } from '@mui/material';
import { Page } from '@components';
import { LoginForm } from '@components/pages/auth/login';
import { useEffect } from 'react';
import { styled } from 'styled-components';

export default function Login() {
  const user = useSelector((state) => state.user.user);
  const navigate = useNavigate();

  useEffect(() => {
    if (user) {
      navigate('/dashboard/app', { replace: true });
    }
  }, [user]);

  return (
    <Page title="Login">
      <Root>
        {!user && (
          <Container maxWidth="sm">
            <Content>
              <Typography sx={{ mb: 5 }} variant="h4" gutterBottom>
                Sign in to Popil
              </Typography>

              <LoginForm />
            </Content>
          </Container>
        )}
      </Root>
    </Page>
  );
}

const Root = styled.div`
  display: flex;
`;

const Content = styled.div`
  max-width: 480px;
  margin: auto;
  min-height: 100vh;
  display: flex;
  justify-content: center;
  flex-direction: column;
  padding: 12px;
`;
