import { createAsyncThunk } from '@reduxjs/toolkit';
import axios from 'axios';
import { env } from '@env';

export const getComments = createAsyncThunk(
  'comments/getComments',
  async (page) => {
    return (
      await axios.get(
        `${env.NEBULA_BASE_URL}/v1/admin/comments?page=${page}&limit=20`
      )
    ).data;
  }
);
