import React, { useRef, useState } from 'react';
import {
  IconButton,
  ListItemIcon,
  ListItemText,
  Menu,
  MenuItem,
} from '@mui/material';
import { Iconify } from '@components';
import { useNews } from '@hooks';

function NewsMoreMenu({ news }) {
  const moreMenuRef = useRef(null);
  const [isOpenMore, setIsOpenMore] = useState(false);

  const {
    handleNewsNotificationModal,
    handleDeleteNews,
    handleAddDailyNews,
    handleAddPopularNews,
  } = useNews();
  return (
    <>
      <IconButton ref={moreMenuRef} onClick={() => setIsOpenMore(true)}>
        <Iconify icon="eva:more-vertical-fill" width={20} height={20} />
      </IconButton>

      <Menu
        open={isOpenMore}
        anchorEl={moreMenuRef.current}
        onClose={() => setIsOpenMore(false)}
        PaperProps={{
          sx: { width: 200, maxWidth: '100%' },
        }}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
      >
        <MenuItem
          sx={{ color: 'text.secondary' }}
          onClick={() =>
            window.open(
              `${window.location.origin}/dashboard/news/edit/${news?._id}`
            )
          }
        >
          <ListItemIcon>
            <Iconify icon="eva:edit-outline" width={24} height={24} />
          </ListItemIcon>
          <ListItemText
            primary="Edit"
            primaryTypographyProps={{ variant: 'body2' }}
          />
        </MenuItem>

        <MenuItem
          sx={{ color: 'text.secondary' }}
          onClick={() => {
            handleNewsNotificationModal(news);
            setIsOpenMore(false);
          }}
        >
          <ListItemIcon>
            <Iconify icon="eva:bell-outline" width={24} height={24} />
          </ListItemIcon>
          <ListItemText
            primary="Push Notify"
            primaryTypographyProps={{ variant: 'body2' }}
          />
        </MenuItem>

        <MenuItem
          sx={{ color: 'text.secondary' }}
          onClick={() => {
            handleAddPopularNews(news?._id);
            setIsOpenMore(false);
          }}
        >
          <ListItemIcon>
            <Iconify icon="eva:trending-up-outline" width={24} height={24} />
          </ListItemIcon>
          <ListItemText
            primary="Add Popular"
            primaryTypographyProps={{ variant: 'body2' }}
          />
        </MenuItem>

        <MenuItem
          sx={{ color: 'text.secondary' }}
          onClick={() => {
            handleAddDailyNews(news?._id);
            setIsOpenMore(false);
          }}
        >
          <ListItemIcon>
            <Iconify icon="eva:sun-outline" width={24} height={24} />
          </ListItemIcon>
          <ListItemText
            primary="Add Daily"
            primaryTypographyProps={{ variant: 'body2' }}
          />
        </MenuItem>

        <MenuItem
          sx={{ color: 'text.secondary' }}
          onClick={() => {
            handleDeleteNews(news?._id);
            setIsOpenMore(false);
          }}
        >
          <ListItemIcon>
            <Iconify icon="eva:trash-outline" width={24} height={24} />
          </ListItemIcon>
          <ListItemText
            primary="Delete"
            primaryTypographyProps={{ variant: 'body2' }}
          />
        </MenuItem>
      </Menu>
    </>
  );
}

export default NewsMoreMenu;
