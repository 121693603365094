import Iconify from '@components/shared/Iconify';

const getIcon = (name) => <Iconify icon={name} width={22} height={22} />;

const navConfig = [
  {
    title: 'dashboard',
    path: '/dashboard/app',
    icon: getIcon('eva:pie-chart-outline'),
  },
  {
    title: 'users',
    path: '/dashboard/user',
    icon: getIcon('eva:people-outline'),
  },
  {
    title: 'news',
    path: '/dashboard/news',
    icon: getIcon('eva:file-text-outline'),
  },
  {
    title: 'news comments',
    path: '/dashboard/comments',
    icon: getIcon('eva:message-circle-outline'),
  },
  {
    title: 'news reads',
    path: '/dashboard/reads',
    icon: getIcon('eva:flash-outline'),
  },

  {
    title: 'categories',
    path: '/dashboard/categories',
    icon: getIcon('eva:grid-outline'),
  },
  {
    title: 'sources',
    path: '/dashboard/sources',
    icon: getIcon('eva:archive-outline'),
  },
  {
    title: 'trends',
    path: '/dashboard/trends',
    icon: getIcon('eva:star-outline'),
  },
  {
    title: 'notifications',
    path: '/dashboard/notification',
    icon: getIcon('eva:bell-outline'),
  },
  /*  {
    title: 'jobs',
    path: '/dashboard/jobs',
    icon: getIcon('oui:ml-create-population-job'),
  },*/
  {
    title: 'settings',
    path: '/dashboard/settings',
    icon: getIcon('eva:settings-outline'),
  },
];

export default navConfig;
