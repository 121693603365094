import { createSlice } from '@reduxjs/toolkit';
import { getTrendDetail, getTrends } from './trends.actions';

const trends = createSlice({
  name: 'trends',
  initialState: {
    trends: null,
    trendDetail: null,
  },
  reducers: {
    setTrends(state, action) {
      state.trends = action.payload;
    },
  },
  extraReducers: {
    [getTrends.fulfilled]: (state, action) => {
      state.trends = action.payload.data;
    },
    [getTrendDetail.fulfilled]: (state, action) => {
      state.trendDetail = action.payload.data;
    },
  },
});

export default trends.reducer;

export const { setTrends } = trends.actions;
