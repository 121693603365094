import { useEffect } from 'react';
import { env } from '@env';
import {
  Avatar,
  Button,
  Card,
  Container,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
  Typography,
} from '@mui/material';
import { Iconify, Label, Page, Scrollbar } from '@components';
import { UserListHead } from '@components/pages/user';
import { useDispatch, useSelector } from 'react-redux';
import { getTrends } from '@features/trends/trends.actions';
import TrendMoreMenu from '@components/pages/trends/TrendMoreMenu';
import { Link as RouterLink } from 'react-router-dom';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import axios from 'axios';
import { setTrends } from '@features/trends/trends.slice';

export default function Trends() {
  const dispatch = useDispatch();

  const { trends } = useSelector((state) => state.trends);

  useEffect(() => {
    dispatch(getTrends());
  }, [dispatch]);

  const handleDragEnd = async (result) => {
    if (!result.destination) return;

    const reorderedTrends = Array?.from(trends);
    const [movedItem] = reorderedTrends?.splice(result.source.index, 1);
    reorderedTrends?.splice(result.destination.index, 0, movedItem);

    if (reorderedTrends) {
      dispatch(setTrends(reorderedTrends));
    }

    const updatedTrends = reorderedTrends?.map((topic, index) => ({
      id: topic._id,
      order: index + 1,
    }));

    try {
      await axios.put(`${env.NEBULA_BASE_URL}/v1/admin/trends/update/sort`, {
        trends: updatedTrends,
      });
      console.log('Topics order updated successfully');
    } catch (error) {
      console.error('Error updating topics order:', error);
    }
  };

  return (
    <Page title="Trends">
      <Container>
        <Stack
          direction="row"
          alignItems="center"
          justifyContent="space-between"
          mb={5}
        >
          <Typography variant="h4" gutterBottom>
            Trends
          </Typography>

          <Button
            variant="contained"
            component={RouterLink}
            to="add"
            startIcon={<Iconify icon="eva:plus-fill" />}
          >
            Add Trends
          </Button>
        </Stack>

        <Card>
          <Scrollbar>
            <TableContainer sx={{ minWidth: 800, px: 4 }}>
              {trends ? (
                <DragDropContext onDragEnd={handleDragEnd}>
                  <Droppable droppableId="topics">
                    {(provided) => (
                      <Table
                        {...provided.droppableProps}
                        ref={provided.innerRef}
                      >
                        <UserListHead
                          headLabel={[
                            { id: '' },
                            { id: '' },
                            { id: 'keys', label: 'Keys', alignRight: false },
                            {
                              id: 'status',
                              label: 'Status',
                              alignRight: false,
                            },
                            { id: '' },
                          ]}
                        />
                        <TableBody>
                          {trends?.map((row, index) => {
                            const { id, _id, title, image, tags, status } = row;
                            return (
                              <Draggable
                                key={_id}
                                draggableId={_id.toString()}
                                index={index}
                              >
                                {(provided) => (
                                  <TableRow
                                    hover
                                    key={_id}
                                    tabIndex={-1}
                                    ref={provided.innerRef}
                                    {...provided.draggableProps}
                                    {...provided.dragHandleProps}
                                  >
                                    <TableCell align="left">{id}</TableCell>
                                    <TableCell
                                      component="th"
                                      scope="row"
                                      padding="none"
                                    >
                                      <Stack
                                        direction="row"
                                        alignItems="center"
                                        spacing={2}
                                      >
                                        <Avatar alt={title} src={image} />
                                        <Typography variant="subtitle2" noWrap>
                                          {title ?? 'Null'}
                                        </Typography>
                                      </Stack>
                                    </TableCell>
                                    <TableCell align="left">{tags}</TableCell>
                                    <TableCell align="left">
                                      <Label
                                        variant="ghost"
                                        color={status ? 'success' : 'error'}
                                      >
                                        {status ? 'active' : 'passive'}
                                      </Label>
                                    </TableCell>

                                    <TableCell align="right">
                                      <TrendMoreMenu
                                        trend={row}
                                        status={status}
                                      />
                                    </TableCell>
                                  </TableRow>
                                )}
                              </Draggable>
                            );
                          })}
                          {provided.placeholder}
                        </TableBody>
                      </Table>
                    )}
                  </Droppable>
                </DragDropContext>
              ) : null}
            </TableContainer>
          </Scrollbar>
        </Card>
      </Container>
    </Page>
  );
}
