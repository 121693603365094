export const env = {
  NEBULA_BASE_URL: 'https://nebula-js.popil.app/api',
  FRONT_URL: 'https://popil.app',
};

/*
  prod:
  NEBULA_BASE_URL: 'https://nebula-js.popil.app/api',
  FRONT_URL: 'https://popil.app',
*/

/*
  test:
  NEBULA_BASE_URL: 'http://localhost:4000/api',
  FRONT_URL: 'https://popil.app',
*/

/*
  localhost:
  NEBULA_BASE_URL: 'http://localhost:4000/api',
  FRONT_URL: 'http://localhost:3000',
*/
