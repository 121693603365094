import { useRef, useState } from 'react';
import { env } from '@env';
import {
  IconButton,
  ListItemIcon,
  ListItemText,
  Menu,
  MenuItem,
} from '@mui/material';
import { Iconify } from '@components';
import axios from 'axios';

export default function CommentMoreMenu({
  commentStatus,
  getTableData,
  page,
  commentId,
}) {
  const ref = useRef(null);
  const [isOpen, setIsOpen] = useState(false);

  const changeCommentStatus = () => {
    axios
      .put(
        `${env.NEBULA_BASE_URL}/v1/admin/comments/update/status/${commentId}`
      )
      .then(() => {
        getTableData(page);
      });
  };

  return (
    <>
      <IconButton ref={ref} onClick={() => setIsOpen(true)}>
        <Iconify icon="eva:more-vertical-fill" width={20} height={20} />
      </IconButton>

      <Menu
        open={isOpen}
        anchorEl={ref.current}
        onClose={() => setIsOpen(false)}
        PaperProps={{
          sx: { width: 200, maxWidth: '100%' },
        }}
        anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
        transformOrigin={{ vertical: 'top', horizontal: 'right' }}
      >
        <MenuItem
          sx={{ color: 'text.secondary' }}
          onClick={changeCommentStatus}
        >
          <ListItemIcon>
            <Iconify icon="eva:loader-outline" width={24} height={24} />
          </ListItemIcon>
          <ListItemText
            primary={commentStatus ? 'Not Show' : 'Show'}
            primaryTypographyProps={{ variant: 'body2' }}
          />
        </MenuItem>

        {/*   <MenuItem component={RouterLink} to="#" sx={{ color: 'text.secondary' }}>
          <ListItemIcon>
            <Iconify icon="eva:edit-fill" width={24} height={24} />
          </ListItemIcon>
          <ListItemText primary="Edit" primaryTypographyProps={{ variant: 'body2' }} />
        </MenuItem>*/}
      </Menu>
    </>
  );
}
