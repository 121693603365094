import { createSlice } from '@reduxjs/toolkit';
import { addSource, getCategorySources, getSources } from './source.actions';

const source = createSlice({
  name: 'source',
  initialState: {
    sources: null,
    sourcesLoading: false,
    openEditSourceModal: false,
    openAddSourceModal: false,
    editSourceFields: null,
    categorySources: null,
    addSourceLoading: false,
  },
  reducers: {
    setOpenEditSourceModal(state, action) {
      state.openEditSourceModal = action.payload;
    },
    setOpenAddSourceModal(state, action) {
      state.openAddSourceModal = action.payload;
    },
    setEditSourceFields(state, action) {
      state.editSourceFields = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getSources.pending, (state) => {
        state.sourcesLoading = true;
      })
      .addCase(getSources.fulfilled, (state, action) => {
        state.sources = action.payload.data;
        state.sourcesLoading = false;
      })
      .addCase(getSources.rejected, (state) => {
        state.sourcesLoading = false;
      })
      .addCase(getCategorySources.fulfilled, (state, action) => {
        state.categorySources = action.payload.data;
      })

      //addSource
      .addCase(addSource.pending, (state) => {
        state.addSourceLoading = true;
      })
      .addCase(addSource.fulfilled, (state) => {
        state.addSourceLoading = false;
      })
      .addCase(addSource.rejected, (state) => {
        state.addSourceLoading = false;
      });
  },
});

export default source.reducer;

export const {
  setOpenEditSourceModal,
  setEditSourceFields,
  setOpenAddSourceModal,
} = source.actions;
